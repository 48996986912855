:root {
  --tw-white: #FFFFFF;
  --tw-black: #000000;
  --tw-grey: #E8E8E8;
  --tw-green: #3e595b;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scrollbar-width: thin;
  scrollbar-color: var(--tw-black) var(--tw-white);
}

/* Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
  width: 0px;
}

*::-webkit-scrollbar-track {
  background: var(--tw-black);
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
  width: 16px;
  background-color: var(--tw-white);
  border-radius: 0px;
  border: 1px solid var(--tw-black);
} */

@layer utilities {
  @responsive {
    .w-1-12 {
      width: calc(calc((1/12))*100% - 1.5rem);
    }
    .w-2-12 {
      width: calc(calc((2/12))*100% - 1.5rem);
    }
    .w-3-12 {
      width: calc(calc((3/12))*100% - 1.5rem);
    }
    .w-4-12 {
      width: calc(calc((4/12))*100% - 1.5rem);
    }
    .w-5-12 {
      width: calc(calc((5/12))*100% - 1.5rem);
    }
    .w-6-12 {
      width: calc(calc((6/12))*100% - 1.5rem);
    }
    .w-7-12 {
      width: calc(calc((7/12))*100% - 1.5rem);
    }
    .w-8-12 {
      width: calc(calc((8/12))*100% - 1.5rem);
    }
    .w-9-12 {
      width: calc(calc((9/12))*100% - 1.5rem);
    }
    .w-10-12 {
      width: calc(calc((10/12))*100% - 1.5rem);
    }
    .w-11-12 {
      width: calc(calc((11/12))*100% - 1.5rem);
    }
    .w-12-12 {
      @apply w-full;
    }
  }

  .animation-delay-200 {
    animation-delay: 0.2s;
  }
  .animation-delay-400 {
    animation-delay: 0.4s;
  }
}


@font-face {
  font-family: 'foliolight';
  src: url('./fonts/urw_-_folio-lig-webfont.woff2') format('woff2'), url('./fonts/urw_-_folio-lig-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'foliomedium';
  src: url('./fonts/urw_-_folio-med-webfont.woff2') format('woff2'), url('./fonts/urw_-_folio-med-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'foliobold';
  src: url('./fonts/urw_-_folio-bol-webfont.woff2') format('woff2'), url('./fonts/urw_-_folio-bol-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'folio_condlight';
  src: url('./fonts/urw_-_foliocon-lig-webfont.woff2') format('woff2'), url('./fonts/urw_-_foliocon-lig-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'folio_condmedium';
  src: url('./fonts/urw_-_foliocon-med-webfont.woff2') format('woff2'), url('./fonts/urw_-_foliocon-med-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'folio_condbold';
  src: url('./fonts/urw_-_foliocon-bol-webfont.woff2') format('woff2'), url('./fonts/urw_-_foliocon-bol-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'foliolight', sans-serif;
  -webkit-overflow-scrolling: touch;
}

a {
  text-decoration: none;
}

strong {
  @apply font-folioMedium;
  font-weight: normal;
}

.project-data-row h1,
.project-data-row h2,
.page h1,
.page h2,
.post .post-text h1,
.post .post-text h2 {
  @apply text-medium font-folioMedium;
  font-weight: normal;
  margin-bottom: .5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-folioMedium;
}

.project-data-row h3,
.project-data-row h4,
.project-data-row h5,
.project-data-row h6,
.page h3,
.page h4,
.page h5,
.page h6,
.post .post-text h3,
.post .post-text h4,
.post .post-text h5,
.post .post-text h6 {
  @apply text-base font-folioMedium;
  font-weight: normal;
  margin-bottom: .5em;
}

@media (max-width: 640px) {
  .project-data-row h1,
  .project-data-row h2,
  .post .post-text h1,
  .post .post-text h2 {
    @apply text-base font-folioMedium;
    font-weight: normal;
    margin-bottom: .5em;
  }

  .project-data-row h3,
  .project-data-row h4,
  .project-data-row h5,
  .project-data-row h6,
  .post .post-text h3,
  .post .post-text h4,
  .post .post-text h5,
  .post .post-text h6 {
    @apply text-base font-folioMedium;
    font-weight: normal;
    margin-bottom: .5em;
  }
}

.page p,
.post .post-text p,
.page ul,
.post .post-text ul {
  @apply text-medium;
  margin-bottom: 1em;
}

.page p + ul,
.post .post-text p + ul,
.page p + ul,
.post .post-text p + ul {
  margin-top: -12px;
}

.page ul,
.post .post-text ul {
  margin-left: 16px;
  list-style-type: disc;
  list-style-position: outside;
}

.page a,
.post a {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

.page a:hover {
  color: #b5ccc8; 
}

.clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.post .clamp {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

@media (max-width: 640px) {
  .post .clamp {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }  
  .post .clamp p {
    display: none;
    margin-bottom: 0;
  }

  .post .clamp p:first-of-type {
    display: -webkit-box;
  }
}

.post:hover button {
  color: #3e595b; 
  /* var(--tw-color-green); */
}

.project-txt p {
  margin-bottom: 1em;
}


.menu-contact p a,
.post p a,
.project-data p a,
.project-txt p a {
  color: #3e595b; 
}

.menu-contact p a:hover,
.post p a:hover,
.project-data p a:hover,
.project-txt p a:hover {
  text-decoration-thickness: 1px;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.project-txt .clamp {
  /* -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box; */
}

.project-data .clamp {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.menu-contact p {
  @apply mb-4 text-sm;
}

/* .contentWidth,
.singleProjectW {
  width: var(--contentWidth) !important;
} */

.splide__track {
  height: 100%;
}


.splide .splide__pagination {
  margin-bottom: 20px;
  bottom: auto;
  top: calc(100vh - 36px);
  z-index: 20;
}

.splide .splide__pagination__page {
  width: 6px;
  height: 6px;
  opacity: .3;
  border-radius: 0;
  margin: 3px 20px;
  background-color: #E8E8E8; 
}

.splide .splide__pagination__page.is-active {
  background-color: #000;
  opacity: 1;
  transform: scale(1);
}

@media (max-width: 640px) {
  .splide .splide__pagination {
    position: relative;
    margin-top: 20px;
    padding: 0;
    top: auto;
  }
  .splide .splide__pagination__page {
    margin: 3px 10px;
    background-color: #E8E8E8; 
  }
}

.collapsibleHeader button {
  padding: 0;
}

#search:focus-visible {
  outline: none;
}

.video-react-time-control {
  display: none;
}

.video-react .video-react-control:before {
  font-size: 20px !important;
  color: white;
}

.video-react .video-react-control:focus:before, 
.video-react .video-react-control:hover:before, 
.video-react .video-react-control:focus {
  text-shadow: none;
}

.video-react .video-react-big-play-button {
  font-size: 6em;
}

.video-react .video-react-menu-button-popup .video-react-menu .video-react-menu-content {
  bottom: 0 !important;
}

.video-react .video-react-volume-bar.video-react-slider-vertical {
  background-color: rgba(115, 133, 159, 0.5);
  box-shadow: none;
  /* height: 3em; */
  /* margin-top: 0 !important;  */
  /* margin-bottom: 0 !important;  */
}

.video-react .video-react-volume-bar.video-react-slider-vertical .video-react-volume-level {
  background-color: transparent !important;
  backdrop-filter: blur(12px) !important;
}

.video-react .video-react-menu-button-popup.video-react-volume-menu-button-vertical .video-react-menu {
  height: 100%;
  margin-bottom: 32px;
}

.video-react .video-react-menu-button-popup .video-react-menu .video-react-menu-content {
  /* background-color: white !important; */
  backdrop-filter: blur(12px) !important;
}
/* .video-react .video-react-volume-bar.video-react-slider-vertical {
  margin-bottom: .25em !important;
}

.video-react .video-react-menu-button-popup .video-react-menu {
  margin-bottom: 0.25em !important;
} */